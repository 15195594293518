import React, { useEffect, useState } from "react"
import "../../styles/header.css"
import { Link } from "gatsby"
import logowhite from "../../images/logo-svg-w.svg"
import logoblack from "../../images/logo-svg.svg"
import StrategicConsulting from "../../images/header/strategic-consulting.svg"
import OperationalExcellance from "../../images/header/operational-excellance.svg"
import MigrationModernization from "../../images/header/migration-modernization.svg"
import AIAutomationIntegration from "../../images/header/ai-automation-integration.svg"
import DigitalEvolutionStratrgy from "../../images/header/digital-evolution-strategy.svg"
import TechAdvisory from "../../images/header/tech-advisory.svg"
import DataInnovationConsulting from "../../images/header/data-innovation-consulting.svg"
import NextGenCloudSolutions from "../../images/header/next-gen-cloud-solution.svg"
import VirtualSupportEcosystem from "../../images/header/virtual-support-ecosystem.svg"
import EnterpriseApplicationServices from "../../images/header/enterprise-application-services.svg"
import CloudInfrastructireOptimization from "../../images/header/cloud-infrastructure-optimization.svg"
import LegacyToCloudTransition from "../../images/header/legacy-to-cloud-transition.svg"
import EnterpriseSystemUpgrades from "../../images/header/enterprise-system-upgrades.svg"
import CommunicationSystemOverhaul from "../../images/header/communication-system-overhaul.svg"
import AlPoweredTransformations from "../../images/header/ai-powered-transformations.svg"
import IntelligentProcessAutomation from "../../images/header/intelligent-process-automation.svg"
import CognitiveComputingServices from "../../images/header/cognitive-computing-services.svg"
import MicrosoftCopilot from "../../images/header/cognitive-computing-services.svg"
import MicrosoftEcosystem from "../../images/header/microsoft-ecosystem.svg"
import LowCodeInnovation from "../../images/header/low-code-innovation.svg"
import AzureCloudService from "../../images/header/azure-cloud-service.svg"
import DataIntelligenceAl from "../../images/header/data-intelligence-and-ai.svg"
import ModernDevelopmentFrameworks from "../../images/header/modern-development-framework.svg"
import AdvancedUIUXTools from "../../images/header/advanced-ui-ux-tools.svg"
import SharePoint from "../../images/header/sharepoint.svg"
import Office365 from "../../images/header/office-365.svg"
import Dynamic365 from "../../images/header/dyanamic-365.svg"
import Teams from "../../images/header/teams.svg"
import PowerApps from "../../images/header/power-apps.svg"
import PowerAutomate from "../../images/header/power-automate.svg"
import OutSystems from "../../images/header/outsystems.svg"
import Nintex from "../../images/header/nintex-header.svg"
import AzureApplications from "../../images/header/azure-applications.svg"
import AzureDataServices from "../../images/header/azure-data-services.svg"
import AzureCloudInfraServices from "../../images/header/azure-cloud-infra-services.svg"
import AzureAIAndCognitiveServices from "../../images/header/azure-ai-and-congitive-services.svg"
import CloudMigrations from "../../images/header/cloud-migration.svg"
import AzureCognitiveServices from "../../images/header/azure-cognative-services.svg"
import PowerBI from "../../images/header/power-bi.svg"
import AzureDataFactory from "../../images/header/azure-data-factory.svg"
import AIModelDevelopment from "../../images/header/ai-model-development.svg"
import AzureSynapseAnalyticsServices from "../../images/header/azure-synapse-analytics-services-header.svg"
import ReactJs from "../../images/header/react.svg"
import NodeJs from "../../images/header/node.svg"
import PowerQuery from "../../images/header/powerquery.svg"
import SPFX from "../../images/header/spfx-header.svg"
import PowerFx from "../../images/header/powerfx.svg"
import NextJs from "../../images/header/nextjs.svg"
import NetCore from "../../images/header/net-core.svg"
import GraphAPI from "../../images/header/graph-api.svg"
import GatsByJs from "../../images/header/gatsbyjs.svg"
import AngularJs from "../../images/header/angular.svg"
import Figma from "../../images/header/figma.svg"
import AdobeXD from "../../images/header/adobeXD.svg"
import IndustrySpecificSolutions from "../../images/header/industry-specific-solutions.svg"
import DataDrivenBusinessModel from "../../images/header/data-driven-business-model.svg"
import DigitalWorkplaceTransformation from "../../images/header/digital-workplace-transformation.svg"
import CloudScalabilitySolutions from "../../images/header/cloud-scalability-solutions.svg"
import About from "../../images/header/about.svg"
import Career from "../../images/header/career.svg"
import Blogs from "../../images/header/blogs.svg"
import Contact from "../../images/header/contact.svg"

const Header = () => {

  const [isHeaderFixed, setIsHeaderFixed] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [isServicesContentVisible, setIsServicesContentVisible] = useState(0);
  const [isTechnologyContentVisible, setIsTechnologyContentVisible] = useState(0);

  const location = window.location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const scrollThreshold = 100;

      if (scrollPosition > scrollThreshold) {
        setIsHeaderFixed(true)
      } else {
        setIsHeaderFixed(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleClick = (number) => {
    setIsActive(prevState => (prevState === number ? 0 : number));
  }

  const handleOptionsClick = (number) => {
    setIsActive(prevState => (prevState === number ? 1 : number));
  }

  const handleServicesClick = (number) => {
    setIsActive(prevState => (prevState === number ? 2 : number));
  }

  const handleTechnologyClick = (number) => {
    setIsActive(prevState => (prevState === number ? 3 : number))
  }

  const handleServicesMouseOver = (number) => {
    setIsServicesContentVisible(number);
  }

  const handleTechnologyMouseOver = (number) => {
    setIsTechnologyContentVisible(number);
  }

  return (
    <>
      <header className={`main-header ${isHeaderFixed ? "header-scroll" : ""} ${location === '/' || location === '/services/' || location === '/solutions/' || location === '/technologies/' ? "home-header" : ""} ${location.startsWith("/auth/admin") || location.startsWith("/auth/employee") || location.startsWith('/employee') || location.startsWith('/admin') ? 'd-none' : ''}`}>
        <div className="header-wrapper">
          <div className="container header-padding">
            <div className="hide-tab">
              <Link to="/">
                {isHeaderFixed && location === '/' ? (
                  <img className="logo-new" src={logoblack} alt="logo" />
                ) : location === '/' || location === '/services/' || location === '/solutions/' || location === '/technologies/' ? (
                  <img className="logo-new" src={logowhite} alt="logo" />
                ) : (
                  <img className="logo-new" src={logoblack} alt="logo" />
                )}

              </Link>
              <nav>
                <ul>
                  <li id="homePage">
                    <div className="menu-title"><Link to="/">Home</Link></div>
                  </li>
                  <li id="servicesList">
                    <Link to="/services" className="menu-title">Services</Link>
                    <div id="techSubMenu" className="services dropdown-submenu ">
                      <div className="tech-grid">
                        <div className="custom-hover-nav">
                          <div className="hover-tab">
                            <div className="tab">
                              <Link to="/services/strategic-consulting">
                                <div
                                  role="button"
                                  tabIndex={0}
                                  data-tab="tab-1"
                                  className={`tablinks ${isServicesContentVisible === 0 ? `active` : ``}`}
                                  onMouseOver={() => handleServicesMouseOver(0)}
                                >
                                  <img className="app-icon" loading="lazy" src={StrategicConsulting} alt="Strategic Consulting" height="32" width="32" />
                                  Strategic Consulting
                                </div>
                              </Link>
                              <Link to="/services/operational-excellence">
                                <div data-tab="tab-2" className={`tablinks ${isServicesContentVisible === 1 ? `active` : ``}`} onMouseOver={() => handleServicesMouseOver(1)}>
                                  <img className="app-icon" loading="lazy" src={OperationalExcellance} alt="Operational Excellence" height="32" width="32" />
                                  Operational Excellence
                                </div>
                              </Link>
                              <Link to="/services/migration-modernization">
                                <div data-tab="tab-3" className={`tablinks ${isServicesContentVisible === 2 ? `active` : ``}`} onMouseOver={() => handleServicesMouseOver(2)}>
                                  <img className="app-icon" loading="lazy" src={MigrationModernization} alt="Migration & Modernization" height="32" width="32" />
                                  Migration & Modernization
                                </div>
                              </Link>
                              <Link to="/services/automation-integration">
                                <div data-tab="tab-4" className={`tablinks ${isServicesContentVisible === 3 ? `active` : ``}`} onMouseOver={() => handleServicesMouseOver(3)}>
                                  <img className="app-icon" loading="lazy" src={AIAutomationIntegration} alt="AI & Automation Integration" height="32" width="32" />
                                  AI & Automation Integration
                                </div>
                              </Link>
                            </div>
                            <div className="tabcontent-wrapper">
                              <div id="tab-1" className={`tabcontent ${isServicesContentVisible === 0 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/services/strategic-consulting/digital-evolution-strategy"><img src={DigitalEvolutionStratrgy} alt="Digital Evolution Strategy" />Digital Evolution Strategy</Link></li>
                                  <li><Link to="/services/strategic-consulting/tech-advisory"><img src={TechAdvisory} alt="Tech Advisory" />Tech Advisory</Link></li>
                                  <li><Link to="/services/strategic-consulting/data-innovation-consulting"><img src={DataInnovationConsulting} alt="Data Innovation Consulting" />Data Innovation Consulting</Link></li>
                                  <li><Link to="/services/strategic-consulting/next-gen-cloud-solutions"><img src={NextGenCloudSolutions} alt="Next-Gen Cloud Solutions" />Next-Gen Cloud Solutions</Link></li>
                                </ul>
                              </div>
                              <div id="tab-2" className={`tabcontent ${isServicesContentVisible === 1 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/services/operational-excellence/enterprise-application-services"><img src={EnterpriseApplicationServices} alt="Enterprise Application Services" />Enterprise Application Services</Link></li>
                                  <li><Link to="/services/operational-excellence/cloud-infrastructure-optimization"><img src={CloudInfrastructireOptimization} alt="Cloud Infrastructure Optimization" />Cloud Infrastructure Optimization</Link></li>
                                  <li><Link to="/services/operational-excellence/virtual-support-ecosystem"><img src={VirtualSupportEcosystem} alt="Virtual Support Ecosystem" />Virtual Support Ecosystem</Link></li>
                                </ul>
                              </div>
                              <div id="tab-3" className={`tabcontent ${isServicesContentVisible === 2 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/services/migration-modernization/legacy-to-cloud-transition"><img src={LegacyToCloudTransition} alt="Legacy to Cloud Transition" />Legacy to Cloud Transition</Link></li>
                                  <li><Link to="/services/migration-modernization/enterprise-system-upgrades"><img src={EnterpriseSystemUpgrades} alt="Enterprise System Upgrades" />Enterprise System Upgrades</Link></li>
                                  <li><Link to="/services/migration-modernization/communication-system-overhaul"><img src={CommunicationSystemOverhaul} alt="Communication System Overhaul" />Communication System Overhaul</Link></li>
                                </ul>
                              </div>
                              <div id="tab-4" className={`tabcontent ${isServicesContentVisible === 3 ? `active` : ``}`}>
                                <ul>
                                  <li> <Link to="/services/automation-integration/ai-powered-transformations"><img src={AlPoweredTransformations} alt="AI-Powered Transformations" />AI-Powered Transformations</Link></li>
                                  <li> <Link to="/services/automation-integration/intelligent-process-automation"><img src={IntelligentProcessAutomation} alt="Intelligent Process Automation" />Intelligent Process Automation</Link></li>
                                  <li> <Link to="/services/automation-integration/cognitive-computing-services"><img src={CognitiveComputingServices} alt="Cognitive Computing Services" />Cognitive Computing Services</Link></li>
                                  <li> <Link to="/services/automation-integration/microsoft-copilot"><img src={MicrosoftCopilot} alt="Microsoft Copilot" />Microsoft Copilot</Link></li>
                                </ul>
                              </div>
                              <div className="border-between"></div>
                              <div className="bottom-box">
                                <div className="bottom-box-content">
                                  <h3>Finding a Tech Partner?</h3>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>We offer tech assistance to small, medium, and enterprise-sized businesses.</p>
                                    <Link to="/contact" className="button-holder">
                                      <button className="button button-2 hover-slide-right">
                                        <span>Let's Chat</span>
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li id="technologyList">
                    <Link to="/technologies" className="menu-title">Technologies & Platforms</Link>
                    <div id="techSubMenu" className="new dropdown-submenu">
                      <div className="tech-grid">
                        <div className="custom-hover-nav">
                          <div className="hover-tab">
                            <div className="tab">
                              <Link to="/technologies/microsoft-ecosystem">
                                <div data-tab="tab-1" className={`tablinks ${isTechnologyContentVisible === 0 ? `active` : ``}`} onMouseOver={() => handleTechnologyMouseOver(0)}>
                                  <img className="app-icon" loading="lazy" src={MicrosoftEcosystem} alt="Microsoft Ecosystem" height="32" width="32" />
                                  Microsoft Ecosystem
                                </div>
                              </Link>
                              <Link to="/technologies/low-code-innovation">
                                <div data-tab="tab-2" className={`tablinks ${isTechnologyContentVisible === 1 ? `active` : ``}`} onMouseOver={() => handleTechnologyMouseOver(1)}>
                                  <img className="app-icon" loading="lazy" src={LowCodeInnovation} alt="Low Code Innovation" height="32" width="32" />
                                  Low Code Innovation
                                </div>
                              </Link>
                              <Link to="/technologies/azure-cloud-services">
                                <div data-tab="tab-3" className={`tablinks ${isTechnologyContentVisible === 2 ? `active` : ``}`} onMouseOver={() => handleTechnologyMouseOver(2)}>
                                  <img className="app-icon" loading="lazy" src={AzureCloudService} alt="Azure Cloud service" height="32" width="32" />
                                  Azure Cloud service
                                </div>
                              </Link>
                              <Link to="/technologies/data-intelligence">
                                <div data-tab="tab-4" className={`tablinks ${isTechnologyContentVisible === 3 ? `active` : ``}`} onMouseOver={() => handleTechnologyMouseOver(3)}>
                                  <img className="app-icon" loading="lazy" src={DataIntelligenceAl} alt="Data Intelligence & AI" height="32" width="32" />
                                  Data Intelligence & AI
                                </div>
                              </Link>
                              <Link to="/technologies/modern-development">
                                <div data-tab="tab-4" className={`tablinks ${isTechnologyContentVisible === 4 ? `active` : ``}`} onMouseOver={() => handleTechnologyMouseOver(4)}>
                                  <img className="app-icon" loading="lazy" src={ModernDevelopmentFrameworks} alt="Modern Development Frameworks" height="32" width="32" />
                                  Modern Development Frameworks
                                </div>
                              </Link>
                            </div>
                            <div className="tabcontent-wrapper">
                              <div id="tab-1" className={`tabcontent ${isTechnologyContentVisible === 0 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/technologies/microsoft-ecosystem/sharepoint"><img src={SharePoint} alt="SharePoint" />SharePoint</Link></li>
                                  <li><Link to="/technologies/microsoft-ecosystem/office-365"><img src={Office365} alt="Office 365" />Office 365</Link></li>
                                  <li><Link to="/technologies/microsoft-ecosystem/dynamics-365"><img src={Dynamic365} alt="Dynamics 365" />Dynamics 365</Link></li>
                                  <li><Link to="/technologies/microsoft-ecosystem/teams"><img src={Teams} alt="Teams" />Teams</Link></li>
                                </ul>
                              </div>
                              <div id="tab-2" className={`tabcontent ${isTechnologyContentVisible === 1 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/technologies/low-code-innovation/powerapps"><img src={PowerApps} alt="PowerApps" />PowerApps</Link></li>
                                  <li><Link to="/technologies/low-code-innovation/power-automate"><img src={PowerAutomate} alt="Power Automate" />Power Automate</Link></li>
                                  <li><Link to="/technologies/low-code-innovation/outsystems"><img src={OutSystems} alt="OutSystems" />OutSystems</Link></li>
                                  <li><Link to="/technologies/low-code-innovation/nintex"><img src={Nintex} alt="Nintex" />Nintex</Link></li>
                                </ul>
                              </div>
                              <div id="tab-3" className={`tabcontent ${isTechnologyContentVisible === 2 ? `active` : ``}`}>
                                <ul>
                                  <li> <Link to="/technologies/azure-cloud-services/azure-applications"><img src={AzureApplications} alt="Azure Applications" />Azure Applications </Link></li>
                                  <li> <Link to="/technologies/azure-cloud-services/cloud-migrations"><img src={CloudMigrations} alt=" Cloud Migrations" /> Cloud Migrations</Link></li>
                                  <li> <Link to="/technologies/azure-cloud-services/azure-data-services"><img src={AzureDataServices} alt="Azure Data Services" />Azure Data Services</Link></li>
                                  <li> <Link to="/technologies/azure-cloud-services/azure-cloud-infra-services"><img src={AzureCloudInfraServices} alt="Azure Cloud Infra Services" />Azure Cloud Infra Services</Link></li>
                                  <li> <Link to="/technologies/azure-cloud-services/azure-ai-and-cognitive-services"><img src={AzureAIAndCognitiveServices} alt="Azure AI and Cognitive Services" />Azure AI and Cognitive Services</Link></li>
                                </ul>
                              </div>
                              <div id="tab-4" className={`tabcontent ${isTechnologyContentVisible === 3 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/technologies/data-intelligence/power-bi"><img src={PowerBI} alt="Power BI" />Power BI</Link></li>
                                  <li><Link to="/technologies/data-intelligence/azure-data-factory"><img src={AzureDataFactory} alt="Azure Data Factory" />Azure Data Factory</Link></li>
                                  <li><Link to="/technologies/data-intelligence/ai-model-development"><img src={AIModelDevelopment} alt="AI model development" />AI model development</Link></li>
                                  <li><Link to="/technologies/data-intelligence/azure-synapse-analytics-services"><img src={AzureSynapseAnalyticsServices} alt="Azure Synapse Analytics Services" />Azure Synapse Analytics Services</Link></li>
                                </ul>
                              </div>
                              <div id="tab-5" className={`tabcontent ${isTechnologyContentVisible === 4 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/technologies/modern-development/react-js"><img src={ReactJs} alt=" React JS" />React JS</Link></li>
                                  <li><Link to="/technologies/modern-development/spfx"><img src={SPFX} alt=" SPFX" />SPFX</Link></li>
                                  <li><Link to="/technologies/modern-development/powerquery"><img src={PowerQuery} alt=" PowerQuery" />PowerQuery</Link></li>
                                  <li><Link to="/technologies/modern-development/powerfx"><img src={PowerFx} alt=" PowerFx" />PowerFx</Link></li>
                                  <li><Link to="/technologies/modern-development/node-js"><img src={NodeJs} alt="Node.js" />Node.js</Link></li>
                                  <li><Link to="/technologies/modern-development/next-js"><img src={NextJs} alt="Next.js" />Next.js</Link></li>
                                  <li><Link to="/technologies/modern-development/net-core"><img src={NetCore} alt=".Net Core" />.Net Core</Link></li>
                                  <li><Link to="/technologies/modern-development/gatsby"><img src={GatsByJs} alt="Gatsby" />Gatsby</Link></li>
                                  <li><Link to="/technologies/modern-development/graph-api"><img src={GraphAPI} alt="Microsoft Graph API" />Microsoft Graph API</Link></li>
                                </ul>
                              </div>
                              <div className="border-between"></div>
                              <div className="bottom-box">
                                <div className="bottom-box-content">
                                  <h3>Finding a Tech Partner?</h3>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>We offer tech assistance to small, medium, and enterprise-sized businesses.</p>
                                    <Link to="/contact" className="button-holder">
                                      <button className="button button-2 hover-slide-right">
                                        <span>Let's Chat</span>
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li id="solutionList">
                    <div className="menu-title">RESOURCES</div>
                    <div id="solutionsSubMenu" className="solutions dropdown-submenu ">
                      <div className="container">
                        <ul className="main-sub-menu">
                          <li>
                            <Link to="/blog">
                              <img loading="lazy" src={Blogs} alt="Blogs" height="27" width="27" />
                              <div>
                                <h6>Our Blogs</h6>
                                <p>Explore the latest trends for the different Technologies with Our Blogs</p>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/case-study">
                              <img loading="lazy" src={DigitalWorkplaceTransformation} alt="Digital Workplace Transformation" height="27" width="27" />
                              <div>
                                <h6>Case Studies</h6>
                                <p>Explore a diverse array of projects where we've tackled challenges, leveraging technologies and strategic insights.</p>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li id="company">
                    <div className="menu-title">COMPANY</div>
                    <div id="companySubMenu" className="dropdown-submenu company">
                      <div className="container">
                        <ul className="main-sub-menu">
                          <li>
                            <Link to="/about">
                              <img loading="lazy" src={About} alt="About" height="27" width="27" />
                              <div>
                                <h6>About us</h6>
                                <p>Our collaborative approach ensures a seamless and successful digital journey for our clients.</p>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/career">
                              <img loading="lazy" src={Career} alt="Career" height="27" width="27" />
                              <div>
                                <h6>Careers</h6>
                                <p>Take your career to the next level and join us on the forefront of digital innovation.</p>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact">
                              <img loading="lazy" src={Contact} alt="Contact" height="27" width="27" />
                              <div>
                                <h6>Contact us</h6>
                                <p>Let's start a conversation about how we can bring your digital vision to life - reach out to us today.</p>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="show-tab">
              <Link to="/">
                {isHeaderFixed && location === '/' ? (
                  <img className="logo-new" src={logoblack} alt="logo" />
                ) : location === '/' || location === '/services/' || location === '/solutions/' || location === '/technologies/' ? (
                  <img className="logo-new" src={logowhite} alt="logo" />
                ) : (
                  <img className="logo-new" src={logoblack} alt="logo" />
                )}
              </Link>
              <nav>
                <div className="nav-mobile active d-flex" onClick={() => handleClick(1)}>
                  <span className="pi pi-bars"></span>
                </div>
                <ul className={`nav-list ${isActive >= 1 ? `active-header` : ''}`}>
                  <li onClick={() => setIsActive(0)} className="has-child">
                    <div className={`home-link`}><Link to="/">Home</Link></div>
                  </li>
                  <li className="has-child nested-child">
                    <div className={`main-link ${(isActive === 2 || isActive === 2.1 || isActive === 2.2 || isActive === 2.3 || isActive === 2.4) ? `svg` : ``}`} onClick={() => handleOptionsClick(2)}>Services</div>
                    <ul className={`nav-sub-dropdown ${(isActive === 2 || isActive === 2.1 || isActive === 2.2 || isActive === 2.3 || isActive === 2.4) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link ${isActive === 2.1 ? `svg` : ``}`} onClick={() => handleServicesClick(2.1)}>Strategic Consulting </div>
                        <ul className={`nav-dropdown ${isActive === 2.1 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/services/strategic-consulting/digital-evolution-strategy">Digital Evolution Strategy</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/strategic-consulting/tech-advisory">Tech Advisory</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/strategic-consulting/data-innovation-consulting">Data Innovation Consulting</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/strategic-consulting/next-gen-cloud-solutions">Next-Gen Cloud Solutions</Link></li>
                          {/* <li onClick={() => setIsActive(0)}><Link to="/services/strategic-consulting/insight-driven-analytics">Insight-Driven Analytics</Link></li> */}
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 2 || isActive === 2.1 || isActive === 2.2 || isActive === 2.3 || isActive === 2.4) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link ${isActive === 2.2 ? `svg` : ``}`} onClick={() => handleServicesClick(2.2)}>Operational Excellence </div>
                        <ul className={`nav-dropdown ${isActive === 2.2 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/services/operational-excellence/enterprise-application-services">Enterprise Application Services</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/operational-excellence/cloud-infrastructure-optimization">Cloud Infrastructure Optimization</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/operational-excellence/virtual-support-ecosystem">Virtual Support Ecosystem</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 2 || isActive === 2.1 || isActive === 2.2 || isActive === 2.3 || isActive === 2.4) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link ${isActive === 2.3 ? `svg` : ``}`} onClick={() => handleServicesClick(2.3)}>Migration & Modernization</div>
                        <ul className={`nav-dropdown ${isActive === 2.3 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/services/migration-modernization/legacy-to-cloud-transition">Legacy to Cloud Transition</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/migration-modernization/enterprise-system-upgrades">Enterprise System Upgrades</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/migration-modernization/communication-system-overhaul">Communication System Overhaul</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 2 || isActive === 2.1 || isActive === 2.2 || isActive === 2.3 || isActive === 2.4) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link ${isActive === 2.4 ? `svg` : ``}`} onClick={() => handleServicesClick(2.4)}>AI & Automation Integration</div>
                        <ul className={`nav-dropdown ${isActive === 2.4 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/services/automation-integration/ai-powered-transformations">AI-Powered Transformations</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/automation-integration/intelligent-process-automation">Intelligent Process Automation</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/automation-integration/cognitive-computing-services">Cognitive Computing Services</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/automation-integration/microsoft-copilot">Microsoft Copilot</Link></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="has-child nested-child">
                    <div className={`main-link ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `svg` : ``}`} onClick={() => handleOptionsClick(3)}>Technologies & Platforms</div>

                    <ul className={`nav-sub-dropdown ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link  ${isActive === 3.1 ? `svg` : ``}`} onClick={() => handleTechnologyClick(3.1)}>Microsoft Ecosystem</div>
                        <ul className={`nav-dropdown ${isActive === 3.1 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/microsoft-ecosystem/sharepoint">SharePoint</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/microsoft-ecosystem/office-365">Office 365</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/microsoft-ecosystem/dynamics-365">Dynamics 365</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/microsoft-ecosystem/teams">Teams</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link  ${isActive === 3.2 ? `svg` : ``}`} onClick={() => handleTechnologyClick(3.2)}>Low Code Innovation </div>
                        <ul className={`nav-dropdown ${isActive === 3.2 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/low-code-innovation/powerapps">PowerApps</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/low-code-innovation/power-automate">Power Automate</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/low-code-innovation/outsystems">OutSystems</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/low-code-innovation/nintex">Nintex</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `d-block` : ``}`}>
                      <li className={`sub-link  ${isActive === 3.3 ? `svg` : ``}`} onClick={() => handleTechnologyClick(3.3)}>
                        <div className={`nav-dropdown ${isActive === 3.3 ? `d-block` : ``}`}>Azure Cloud Services </div>
                        <ul className="nav-dropdown">
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/azure-cloud-services/azure-applications">Azure Applications</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/azure-cloud-services/cloud-migrations">Cloud Migrations</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/azure-cloud-services/azure-data-services">Azure Data Services</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/azure-cloud-services/azure-cloud-infra-services">Azure Cloud Infra Services</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/azure-cloud-services/azure-ai-and-cognitive-services">Azure AI and Cognitive Services</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link  ${isActive === 3.4 ? `svg` : ``}`} onClick={() => handleTechnologyClick(3.4)}>Data Intelligence & AI</div>
                        <ul className={`nav-dropdown ${isActive === 3.4 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/data-intelligence/power-bi">Power BI</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/data-intelligence/azure-data-factory">Azure Data Factory</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/data-intelligence/ai-model-development">AI model development</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/data-intelligence/azure-synapse-analytics-services">Azure Synapse Analytics Services</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link  ${isActive === 3.5 ? `svg` : ``}`} onClick={() => handleTechnologyClick(3.5)}>Modern Development Frameworks</div>
                        <ul className={`nav-dropdown ${isActive === 3.5 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/react-js">React JS</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/spfx">SPFX</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/powerquery">PowerQuery</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/powerfx">PowerFx</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/node-js">Node.js</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/next-js">Next.js</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/net-core">.Net Core</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/gatsby">Gatsby</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/graph-api">Microsoft Graph API</Link></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="has-child">
                    <div className={`main-link ${isActive === 4 ? `svg` : ``}`} onClick={() => handleOptionsClick(4)}>Resources</div>
                    <ul className={`nav-dropdown ${isActive === 4 ? `d-block` : ``}`}>
                      <li onClick={() => setIsActive(0)}><Link to="/case-study">Case Studies</Link></li>
                    </ul>
                  </li>
                  <li className="has-child">
                    <div className={`main-link ${isActive === 5 ? `svg` : ``}`} onClick={() => handleOptionsClick(5)}>Company</div>
                    <ul className={`nav-dropdown ${isActive === 5 ? `d-block` : ``}`}>
                      <li onClick={() => setIsActive(0)}><Link to="/about">About us</Link></li>
                      <li onClick={() => setIsActive(0)}><Link to="/career">Careers</Link></li>
                      <li onClick={() => setIsActive(0)}><Link to="/contact">Contact us</Link></li>
                    </ul>
                  </li>
                  <li className="d-flex"><Link className="btn" to="/contact">Get In Touch</Link></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
