exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-admin-add-casestudy-index-js": () => import("./../../../src/pages/admin/add-casestudy/index.js" /* webpackChunkName: "component---src-pages-admin-add-casestudy-index-js" */),
  "component---src-pages-admin-applicants-index-js": () => import("./../../../src/pages/admin/applicants/index.js" /* webpackChunkName: "component---src-pages-admin-applicants-index-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-inquiry-index-js": () => import("./../../../src/pages/admin/inquiry/index.js" /* webpackChunkName: "component---src-pages-admin-inquiry-index-js" */),
  "component---src-pages-admin-jobs-index-js": () => import("./../../../src/pages/admin/jobs/index.js" /* webpackChunkName: "component---src-pages-admin-jobs-index-js" */),
  "component---src-pages-admin-post-blog-index-js": () => import("./../../../src/pages/admin/post-blog/index.js" /* webpackChunkName: "component---src-pages-admin-post-blog-index-js" */),
  "component---src-pages-admin-seo-index-js": () => import("./../../../src/pages/admin/seo/index.js" /* webpackChunkName: "component---src-pages-admin-seo-index-js" */),
  "component---src-pages-blog-[title]-js": () => import("./../../../src/pages/blog/[title].js" /* webpackChunkName: "component---src-pages-blog-[title]-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-case-study-[title]-js": () => import("./../../../src/pages/case-study/[title].js" /* webpackChunkName: "component---src-pages-case-study-[title]-js" */),
  "component---src-pages-case-study-index-js": () => import("./../../../src/pages/case-study/index.js" /* webpackChunkName: "component---src-pages-case-study-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-employee-index-js": () => import("./../../../src/pages/employee/index.js" /* webpackChunkName: "component---src-pages-employee-index-js" */),
  "component---src-pages-employee-post-blog-index-js": () => import("./../../../src/pages/employee/post-blog/index.js" /* webpackChunkName: "component---src-pages-employee-post-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policies-index-js": () => import("./../../../src/pages/privacy-policies/index.js" /* webpackChunkName: "component---src-pages-privacy-policies-index-js" */),
  "component---src-pages-services-automation-integration-ai-powered-transformations-index-js": () => import("./../../../src/pages/services/automation-integration/ai-powered-transformations/index.js" /* webpackChunkName: "component---src-pages-services-automation-integration-ai-powered-transformations-index-js" */),
  "component---src-pages-services-automation-integration-cognitive-computing-services-index-js": () => import("./../../../src/pages/services/automation-integration/cognitive-computing-services/index.js" /* webpackChunkName: "component---src-pages-services-automation-integration-cognitive-computing-services-index-js" */),
  "component---src-pages-services-automation-integration-index-js": () => import("./../../../src/pages/services/automation-integration/index.js" /* webpackChunkName: "component---src-pages-services-automation-integration-index-js" */),
  "component---src-pages-services-automation-integration-intelligent-process-automation-index-js": () => import("./../../../src/pages/services/automation-integration/intelligent-process-automation/index.js" /* webpackChunkName: "component---src-pages-services-automation-integration-intelligent-process-automation-index-js" */),
  "component---src-pages-services-automation-integration-microsoft-copilot-index-js": () => import("./../../../src/pages/services/automation-integration/microsoft-copilot/index.js" /* webpackChunkName: "component---src-pages-services-automation-integration-microsoft-copilot-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-migration-modernization-communication-system-overhaul-index-js": () => import("./../../../src/pages/services/migration-modernization/communication-system-overhaul/index.js" /* webpackChunkName: "component---src-pages-services-migration-modernization-communication-system-overhaul-index-js" */),
  "component---src-pages-services-migration-modernization-enterprise-system-upgrades-index-js": () => import("./../../../src/pages/services/migration-modernization/enterprise-system-upgrades/index.js" /* webpackChunkName: "component---src-pages-services-migration-modernization-enterprise-system-upgrades-index-js" */),
  "component---src-pages-services-migration-modernization-index-js": () => import("./../../../src/pages/services/migration-modernization/index.js" /* webpackChunkName: "component---src-pages-services-migration-modernization-index-js" */),
  "component---src-pages-services-migration-modernization-legacy-to-cloud-transition-index-js": () => import("./../../../src/pages/services/migration-modernization/legacy-to-cloud-transition/index.js" /* webpackChunkName: "component---src-pages-services-migration-modernization-legacy-to-cloud-transition-index-js" */),
  "component---src-pages-services-operational-excellence-cloud-infrastructure-optimization-index-js": () => import("./../../../src/pages/services/operational-excellence/cloud-infrastructure-optimization/index.js" /* webpackChunkName: "component---src-pages-services-operational-excellence-cloud-infrastructure-optimization-index-js" */),
  "component---src-pages-services-operational-excellence-enterprise-application-services-index-js": () => import("./../../../src/pages/services/operational-excellence/enterprise-application-services/index.js" /* webpackChunkName: "component---src-pages-services-operational-excellence-enterprise-application-services-index-js" */),
  "component---src-pages-services-operational-excellence-index-js": () => import("./../../../src/pages/services/operational-excellence/index.js" /* webpackChunkName: "component---src-pages-services-operational-excellence-index-js" */),
  "component---src-pages-services-operational-excellence-virtual-support-ecosystem-index-js": () => import("./../../../src/pages/services/operational-excellence/virtual-support-ecosystem/index.js" /* webpackChunkName: "component---src-pages-services-operational-excellence-virtual-support-ecosystem-index-js" */),
  "component---src-pages-services-strategic-consulting-data-innovation-consulting-index-js": () => import("./../../../src/pages/services/strategic-consulting/data-innovation-consulting/index.js" /* webpackChunkName: "component---src-pages-services-strategic-consulting-data-innovation-consulting-index-js" */),
  "component---src-pages-services-strategic-consulting-digital-evolution-strategy-index-js": () => import("./../../../src/pages/services/strategic-consulting/digital-evolution-strategy/index.js" /* webpackChunkName: "component---src-pages-services-strategic-consulting-digital-evolution-strategy-index-js" */),
  "component---src-pages-services-strategic-consulting-index-js": () => import("./../../../src/pages/services/strategic-consulting/index.js" /* webpackChunkName: "component---src-pages-services-strategic-consulting-index-js" */),
  "component---src-pages-services-strategic-consulting-next-gen-cloud-solutions-index-js": () => import("./../../../src/pages/services/strategic-consulting/next-gen-cloud-solutions/index.js" /* webpackChunkName: "component---src-pages-services-strategic-consulting-next-gen-cloud-solutions-index-js" */),
  "component---src-pages-services-strategic-consulting-tech-advisory-index-js": () => import("./../../../src/pages/services/strategic-consulting/tech-advisory/index.js" /* webpackChunkName: "component---src-pages-services-strategic-consulting-tech-advisory-index-js" */),
  "component---src-pages-solutions-cloud-scalability-index-js": () => import("./../../../src/pages/solutions/cloud-scalability/index.js" /* webpackChunkName: "component---src-pages-solutions-cloud-scalability-index-js" */),
  "component---src-pages-solutions-data-drive-index-js": () => import("./../../../src/pages/solutions/data-drive/index.js" /* webpackChunkName: "component---src-pages-solutions-data-drive-index-js" */),
  "component---src-pages-solutions-digital-workplace-index-js": () => import("./../../../src/pages/solutions/digital-workplace/index.js" /* webpackChunkName: "component---src-pages-solutions-digital-workplace-index-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-industry-specific-index-js": () => import("./../../../src/pages/solutions/industry-specific/index.js" /* webpackChunkName: "component---src-pages-solutions-industry-specific-index-js" */),
  "component---src-pages-technologies-advanced-tools-index-js": () => import("./../../../src/pages/technologies/advanced-tools/index.js" /* webpackChunkName: "component---src-pages-technologies-advanced-tools-index-js" */),
  "component---src-pages-technologies-azure-cloud-services-azure-ai-and-cognitive-services-index-js": () => import("./../../../src/pages/technologies/azure-cloud-services/azure-ai-and-cognitive-services/index.js" /* webpackChunkName: "component---src-pages-technologies-azure-cloud-services-azure-ai-and-cognitive-services-index-js" */),
  "component---src-pages-technologies-azure-cloud-services-azure-applications-index-js": () => import("./../../../src/pages/technologies/azure-cloud-services/azure-applications/index.js" /* webpackChunkName: "component---src-pages-technologies-azure-cloud-services-azure-applications-index-js" */),
  "component---src-pages-technologies-azure-cloud-services-azure-cloud-infra-services-index-js": () => import("./../../../src/pages/technologies/azure-cloud-services/azure-cloud-infra-services/index.js" /* webpackChunkName: "component---src-pages-technologies-azure-cloud-services-azure-cloud-infra-services-index-js" */),
  "component---src-pages-technologies-azure-cloud-services-azure-data-services-index-js": () => import("./../../../src/pages/technologies/azure-cloud-services/azure-data-services/index.js" /* webpackChunkName: "component---src-pages-technologies-azure-cloud-services-azure-data-services-index-js" */),
  "component---src-pages-technologies-azure-cloud-services-cloud-migrations-index-js": () => import("./../../../src/pages/technologies/azure-cloud-services/cloud-migrations/index.js" /* webpackChunkName: "component---src-pages-technologies-azure-cloud-services-cloud-migrations-index-js" */),
  "component---src-pages-technologies-azure-cloud-services-index-js": () => import("./../../../src/pages/technologies/azure-cloud-services/index.js" /* webpackChunkName: "component---src-pages-technologies-azure-cloud-services-index-js" */),
  "component---src-pages-technologies-data-intelligence-ai-model-development-index-js": () => import("./../../../src/pages/technologies/data-intelligence/ai-model-development/index.js" /* webpackChunkName: "component---src-pages-technologies-data-intelligence-ai-model-development-index-js" */),
  "component---src-pages-technologies-data-intelligence-azure-data-factory-index-js": () => import("./../../../src/pages/technologies/data-intelligence/azure-data-factory/index.js" /* webpackChunkName: "component---src-pages-technologies-data-intelligence-azure-data-factory-index-js" */),
  "component---src-pages-technologies-data-intelligence-azure-synapse-analytics-services-index-js": () => import("./../../../src/pages/technologies/data-intelligence/azure-synapse-analytics-services/index.js" /* webpackChunkName: "component---src-pages-technologies-data-intelligence-azure-synapse-analytics-services-index-js" */),
  "component---src-pages-technologies-data-intelligence-index-js": () => import("./../../../src/pages/technologies/data-intelligence/index.js" /* webpackChunkName: "component---src-pages-technologies-data-intelligence-index-js" */),
  "component---src-pages-technologies-data-intelligence-power-bi-index-js": () => import("./../../../src/pages/technologies/data-intelligence/power-bi/index.js" /* webpackChunkName: "component---src-pages-technologies-data-intelligence-power-bi-index-js" */),
  "component---src-pages-technologies-index-js": () => import("./../../../src/pages/technologies/index.js" /* webpackChunkName: "component---src-pages-technologies-index-js" */),
  "component---src-pages-technologies-low-code-innovation-index-js": () => import("./../../../src/pages/technologies/low-code-innovation/index.js" /* webpackChunkName: "component---src-pages-technologies-low-code-innovation-index-js" */),
  "component---src-pages-technologies-low-code-innovation-nintex-index-js": () => import("./../../../src/pages/technologies/low-code-innovation/nintex/index.js" /* webpackChunkName: "component---src-pages-technologies-low-code-innovation-nintex-index-js" */),
  "component---src-pages-technologies-low-code-innovation-outsystems-index-js": () => import("./../../../src/pages/technologies/low-code-innovation/outsystems/index.js" /* webpackChunkName: "component---src-pages-technologies-low-code-innovation-outsystems-index-js" */),
  "component---src-pages-technologies-low-code-innovation-power-automate-index-js": () => import("./../../../src/pages/technologies/low-code-innovation/power-automate/index.js" /* webpackChunkName: "component---src-pages-technologies-low-code-innovation-power-automate-index-js" */),
  "component---src-pages-technologies-low-code-innovation-powerapps-index-js": () => import("./../../../src/pages/technologies/low-code-innovation/powerapps/index.js" /* webpackChunkName: "component---src-pages-technologies-low-code-innovation-powerapps-index-js" */),
  "component---src-pages-technologies-microsoft-ecosystem-dynamics-365-index-js": () => import("./../../../src/pages/technologies/microsoft-ecosystem/dynamics-365/index.js" /* webpackChunkName: "component---src-pages-technologies-microsoft-ecosystem-dynamics-365-index-js" */),
  "component---src-pages-technologies-microsoft-ecosystem-index-js": () => import("./../../../src/pages/technologies/microsoft-ecosystem/index.js" /* webpackChunkName: "component---src-pages-technologies-microsoft-ecosystem-index-js" */),
  "component---src-pages-technologies-microsoft-ecosystem-office-365-index-js": () => import("./../../../src/pages/technologies/microsoft-ecosystem/office-365/index.js" /* webpackChunkName: "component---src-pages-technologies-microsoft-ecosystem-office-365-index-js" */),
  "component---src-pages-technologies-microsoft-ecosystem-sharepoint-index-js": () => import("./../../../src/pages/technologies/microsoft-ecosystem/sharepoint/index.js" /* webpackChunkName: "component---src-pages-technologies-microsoft-ecosystem-sharepoint-index-js" */),
  "component---src-pages-technologies-microsoft-ecosystem-teams-index-js": () => import("./../../../src/pages/technologies/microsoft-ecosystem/teams/index.js" /* webpackChunkName: "component---src-pages-technologies-microsoft-ecosystem-teams-index-js" */),
  "component---src-pages-technologies-modern-development-angular-js-index-js": () => import("./../../../src/pages/technologies/modern-development/angular-js/index.js" /* webpackChunkName: "component---src-pages-technologies-modern-development-angular-js-index-js" */),
  "component---src-pages-technologies-modern-development-gatsby-index-js": () => import("./../../../src/pages/technologies/modern-development/gatsby/index.js" /* webpackChunkName: "component---src-pages-technologies-modern-development-gatsby-index-js" */),
  "component---src-pages-technologies-modern-development-graph-api-index-js": () => import("./../../../src/pages/technologies/modern-development/graph-api/index.js" /* webpackChunkName: "component---src-pages-technologies-modern-development-graph-api-index-js" */),
  "component---src-pages-technologies-modern-development-index-js": () => import("./../../../src/pages/technologies/modern-development/index.js" /* webpackChunkName: "component---src-pages-technologies-modern-development-index-js" */),
  "component---src-pages-technologies-modern-development-net-core-index-js": () => import("./../../../src/pages/technologies/modern-development/net-core/index.js" /* webpackChunkName: "component---src-pages-technologies-modern-development-net-core-index-js" */),
  "component---src-pages-technologies-modern-development-next-js-index-js": () => import("./../../../src/pages/technologies/modern-development/next-js/index.js" /* webpackChunkName: "component---src-pages-technologies-modern-development-next-js-index-js" */),
  "component---src-pages-technologies-modern-development-node-js-index-js": () => import("./../../../src/pages/technologies/modern-development/node-js/index.js" /* webpackChunkName: "component---src-pages-technologies-modern-development-node-js-index-js" */),
  "component---src-pages-technologies-modern-development-powerfx-index-js": () => import("./../../../src/pages/technologies/modern-development/powerfx/index.js" /* webpackChunkName: "component---src-pages-technologies-modern-development-powerfx-index-js" */),
  "component---src-pages-technologies-modern-development-powerquery-index-js": () => import("./../../../src/pages/technologies/modern-development/powerquery/index.js" /* webpackChunkName: "component---src-pages-technologies-modern-development-powerquery-index-js" */),
  "component---src-pages-technologies-modern-development-react-js-index-js": () => import("./../../../src/pages/technologies/modern-development/react-js/index.js" /* webpackChunkName: "component---src-pages-technologies-modern-development-react-js-index-js" */),
  "component---src-pages-technologies-modern-development-spfx-index-js": () => import("./../../../src/pages/technologies/modern-development/spfx/index.js" /* webpackChunkName: "component---src-pages-technologies-modern-development-spfx-index-js" */),
  "component---src-pages-tms-support-index-js": () => import("./../../../src/pages/tms-support/index.js" /* webpackChunkName: "component---src-pages-tms-support-index-js" */)
}

