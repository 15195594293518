
import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    tenantId: '38d76346-d9d4-499d-aaf1-3c3b1c9adf69',
    clientId: '2aee96b8-4944-43ff-8718-e021c405fafc',
    authority: 'https://login.microsoftonline.com/38d76346-d9d4-499d-aaf1-3c3b1c9adf69',
    // redirectUri: 'http://localhost:8000/employee',
    redirectUri: 'https://instacloudsolutions.com/employee',
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  }
};
export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ["User.Read"],
};

export const appRoles = {
  Admin: "",
};
