import * as React from "react"
import 'bootstrap/dist/css/bootstrap.css'
import "primeicons/primeicons.css"
import "primereact/resources/themes/lara-light-cyan/theme.css"
import "./src/styles/global.css"
import 'aos/dist/aos.css';
import ScrollSlider from "./src/components/global/ScrollSlider"
import Layout from "./src/components/global/Layout"
import { AuthProvider } from "./src/auth/AuthProvider"

// import MouseTrack from "./src/components/MouseTrack"

export const wrapPageElement = ({ element, props }) => {
    return (
        <AuthProvider>
            {/* <MouseTrack /> */}
            <ScrollSlider />
            <Layout {...props}>{element}</Layout>
        </AuthProvider>
    )
}
