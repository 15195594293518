import React, { useState, useEffect, createContext, useContext } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../authconfig";
import { API_URL } from "../utils/api-url";

const msalInstance = new PublicClientApplication(msalConfig);
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isMsalReady, setIsMsalReady] = useState(false);

    useEffect(() => {
        const initializeMsal = async () => {
            await msalInstance.initialize();
            setIsMsalReady(true);

            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                try {
                    const userRole = await fetch(`${API_URL}/employee`);
                    const res = await userRole.json();
                    const currUser = res?.data?.filter((val) => val?.email?.toLowerCase() === accounts[0]?.username?.toLowerCase());
                    setUser({ ...accounts[0], role: currUser?.[0]?.role });
                } catch (error) {
                    console.error("Error :", error);
                }
            }
        };

        initializeMsal();
    }, []);

    const login = async () => {
        if (!isMsalReady) {
            console.error("MSAL is not ready yet.");
            return;
        }

        try {
            const response = await msalInstance.loginPopup(loginRequest);
            const userRole = await fetch(`${API_URL}/employee`);
            const res = await userRole.json();
            const currUser = res?.data?.filter((val) => val?.email?.toLowerCase() === response.account?.username?.toLowerCase());
            setUser({ ...response.account, role: currUser?.[0]?.role });
        } catch (error) {
            console.error("Login failed", error);
        }
    };

    const logout = () => {
        msalInstance.logoutPopup();
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
